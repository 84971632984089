.flag{
  width: 300px;
  height: 60px;
  border-radius: 50px;
  background-color: #258CD695;
  border: 2px solid #258CD6;
  padding: 10px;
}
.flagBody {
  width: 300px;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flagBody .colA {
  color: red;
}
.flagBody .colB {
  color: #ffffff;
  font-size: 1.5rem;
}
a{
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  
  .flag{
    background-color: #258cd6e3;
    color: white;
    width: 250px;
    height: 30px;
  }
  .flagBody {
    width: 250px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .flagBody .colB {
    color: #fdfeff;
    font-size: 1rem;
  }
  }
a {
  text-decoration: none;
}
ul {
  list-style-type: none;
  padding: 0;
}
.navbarWelcome{
  width: 100%;
  height: 110px;
  background-color: #258CD6;
  background: linear-gradient(to top, #fff, #258cd636); 
}
nav {
  width: 1400px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  padding: 25px;
  margin-bottom: 20px;
  position: fixed;
  z-index: 10;
 }

nav ul {
  display: flex;
  width: 800px;
  height: 60px;
  justify-content: flex-end;
  align-items: center;
  color: rgb(66, 66, 66);
  margin-right: 60px;
  /* background-color: yellow; */
}
nav img {
  width: 90px;
  height: 90px;
}

nav ul li{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 30px;
  border-radius: 50px;
  background-color: #94bcd963;
}

.btnLogout {
  display: flex;
  align-items: center;
  border: none !important;
  background-color: transparent;
}

.btnLogout:hover {
  font-weight: bold !important;
}
.navTitle {
  font-size: 1.5rem;
  font-weight: thin;
}
.logoutIco{
  margin-left: 18px;
  width: 25px;
  height: 25px;
}


 /* NavBar.css */
.navbarWelcome {
  width: 100%;
  height: 110px;
  background-color: #258CD6;
  background: linear-gradient(to top, #fff, #258cd636);
}

nav {
  width: 1400px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  margin-bottom: 20px;
  position: fixed;
  z-index: 10;
  transition: background-color 0.3s ease-in-out; /* Add smooth transition */
}

.scrolled { /* Define styles for scrolled state */
  background-color: #fff; /* Change background color on scroll */
}

nav ul {
  display: flex;
  width: 800px;
  height: 60px;
  justify-content: flex-end;
  align-items: center;
  color: rgb(66, 66, 66);
  margin-right: 60px;
}

nav img {
  width: 90px;
  height: 90px;
}

nav ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 30px;
  border-radius: 50px;
  background-color: #94bcd963;
}

.btnLogout {
  display: flex;
  align-items: center;
  border: none !important;
  background-color: transparent;
}

.btnLogout:hover {
  font-weight: bold !important;
}

.navTitle {
  font-size: 1.5rem;
  font-weight: thin;
}

.logoutIco {
  margin-left: 18px;
  width: 25px;
  height: 25px;
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  body {
    font-size: 12px;
  }
  nav {
    height: 60px;
  }

  nav ul {
    display: none;
  }

  nav img {
    width: 60px;
    height: 60px;
  }

  .navTitle {
    font-size: 0.8rem;
    font-weight: thin;
  }
}

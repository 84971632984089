@import url('https://fonts.googleapis.com/css2?family=Gluten&family=K2D:wght@200&display=swap');

.menuMobile {
  display: flex;
  width: 366px;
  justify-content: center;
}
.menuRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 366px;
  height: 60px;
  gap: 12px;
  margin-bottom: 20px;
}
.menuLink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 20px;
  border: 1px solid #258CD6;
  border-radius: 50px;
}

.contentMobile {
  display: flex;
  width: 350px;
  height: 650px auto;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px;
  gap: 5px;
}
.cardMobile {
  display: flex;
  flex-wrap: wrap;
  width: 336px;
  height: 175px auto;
  padding: 5px;
  border-radius: 10px;
  font-family: "K2D", "Helvetica Neue", Helvetica, Arial;
  font-size: 11px;
  background: linear-gradient(to top, #fff, #258cd632);
  border-bottom: 0.5px solid #edf0f2a3;
}
.cardheadMobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  z-index: 1;
  gap: 15px;
  position: relative;
}
.cardheadBkgMobile {
  width: 336px;
  height: 100px;
  margin-left: -20px;
  margin-top: -20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: absolute;
  background: linear-gradient(to top, #e2dcdc75, #258cd680);
  z-index: 0;
}

.cardheadMobile img {
  width: 40px;
  height: 40px;
}
.cardCnpjMobile {
  display: flex;
  justify-content: flex-end;
  font-size: 10px;
  font-weight: bold;
  margin-top: 35px;
}
.contactMobile {
  width: 336px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 10px;
 
}
.cardBlockMobile {
  display: flex;
  flex-wrap: wrap;
  width: 336px;
  flex-direction: column;
  gap: 5px;
  position: relative;
}
.phoneNumber {
  width: 336px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.cardRowMobile {
  display: flex;
  flex-wrap: wrap;
  width: 336px;
}
.addressRow {
  width: 336px;
  display: flex;
  flex-direction: column;
}
.cardMobile span {
  font-size: 11px;
  color: #258cd6;
  font-weight: bold;
}
.cardRowMobile .emailMobile {
  width: 336px;
  display: flex;
  flex-wrap: wrap;
}
.contactMobile img {
  width: 25px;
  height: 25px;
}
.contactLinkMobile {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 25px;
}
.contactLinkMobile img {
  width: 20px;
  height: 20px;
  margin-left: 15px;
}
.servicesMobile {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}
.serviceTypeItems {
  display: flex;
  justify-content: space-between;
}
a {
  color: black;
}
a:hover {
    font-weight: bold;
    }
    .email a {
      margin-left: 0px;
      }

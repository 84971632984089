@import url('https://fonts.googleapis.com/css2?family=Gluten&family=K2D:wght@200&family=Oswald:wght@400&display=swap');

.section1{
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  /* padding-top: 100px; */
  background-image: url('../../../public/img/bkg-lines.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(42, 42, 42);
  color: rgb(167, 219, 219);

}
@keyframes grow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}
.sec1Main {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1200px;
  height: 700px auto;
  /* background-color: aqua; */
}
.bkg-lines{
  background-image: url('../../../public/img/bkg-lines.png');
  background-repeat: no-repeat;
  background-size: cover;
  animation: grow 10s infinite alternate; 
}
.sec1Slogan {
  width: 100%;
  position: absolute;
  text-align: right;
  font-size: 25px;
  margin-top: 270px;
  margin-right: 90px;
  font-family: 'Oswald', sans-serif;
  color: rgb(57, 71, 71);
}
p {
  font-size: 20px;
}
.sec1Text {
  color: rgb(92, 90, 90);
  text-shadow: 3px 3px 5px #2a92a6;
  position: absolute;
  text-align: left;
  font-size: 55px;
  font-weight: bold;
  font-family: K2D;
  margin-top: -200px;
  margin-left: 250px;
}
.sec1A {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  height: 700px;
  margin-top: 50px;
  /* background-color: yellow; */
}
.sec1A img {
  height: 500px;
  margin-left: -150px;
}

.sec1B { 
  width: 50%;
  height: 700px;
  /* background-color: rgb(29, 20, 195); */
}

.sec1Footer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  color: rgb(92, 90, 90);
  position: absolute;
  font-size: 30px;
  font-weight: bold;
  font-family: K2D;
  margin-top: 500px;
  margin-bottom: 0;
}
.sec1ColABC {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 100px;
}
.sec1ColABC span:hover {
  transform: scale(1.2); 
}
.sec1ColABC span {
  z-index: 2;
  text-shadow: 3px 3px 5px #2a92a6;
  display: inline-block; 
  transition: transform 0.3s ease;
}
.sec1ColABC img {
  position: absolute;
  z-index: 1;
}
body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
ul {
  list-style-type: none;
  padding: 0;
}

main {
  margin: 0px;
  padding: 0px;
  width: 100vw;
  height: 100vh;
}

.global {
  margin-top: 0px;
  padding: 0px;
  display: flex;
  gap: 10px;
}

aside {
  display: flex;
  flex-direction: column;
  width: 200px;
  min-height: 100vh;
  /* background-color: #258CD6; */
  background: linear-gradient(to left, #ffffff4d, #258cd610);
  /* margin-top: 120px; */
  padding: 10px;
  position: fixed;
  z-index: 10;
}
.asideLink {
  width: 180px;
  height: 40px;
  display: flex;
  gap: 15px;
  color: #258cd6e2;
}
.asideLink:hover {
  font-weight: bold;
  cursor: pointer;
  color: #258cd6e2;
}
.asideIco{
  width: 20px;
  height: 20px;
}
.dropdown .dropdown-toggle {
  margin-left: 15px;
  justify-content: flex-start;
}
.dropdown-toggle {
  display: flex;
  justify-content: center;
  width: 200px;
  height: 40px;
  border: none;
  background-color: #d1e6f610;
  font-size: 16px;
}
.dropdown-menu{
  display: none;
}
.dropdown-menu.show {
  display: flex;
  flex-direction: column;
  width: 160px;
  height: 40px auto;
  gap: 15px;
  padding: 15px;
  align-items: flex-start;
  border-radius: 5px;
  color: #fcfeff;
  /* background-color: #4193d22b; */
  background-color: #799fbc;
}
.dropdown-menu.show span {
  color: #f0f4f6;
}
.center {
  width: 1270px;
  min-height: 80vh;
  height: auto;
  background: linear-gradient(to right, #fff, #258cd610);
  /* margin-top: 120px; */
  margin-left: 230px;
}
form input {
  width: 400px;
  height: 15px;
  border-radius: 50px;
  border: 0.5px solid;
  background-color: #258cd614;
  padding: 2px;
  text-align: center;
}
form button {
  width: 200px;
  height: 20px;
  margin-left: 10px;
}
.welcomeSearch form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
section.menuMobile {
  display: none;
}
.contentMobile .cardMobile {
  display: none;
}
.loading-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  body {
    font-size: 12px;
    overflow-x: hidden;
  }
  main {
    overflow-x: hidden;
  }
  .global {
    margin-top: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
    gap: 10px;
    overflow-x: hidden;
  }
  nav {
    display: none;
  }
  aside {
    display: none;
  }
  section.menuMobile {
    display: block;
  }
  .card {
    display: none;
  }
  .contentMobile .cardMobile{
    display: flex;
  }

  .center {
    width: 350px;
    min-height: 650px;
    background: linear-gradient(to right, #fff, #258cd610);
    /* margin-top: 120px; */
  }
  .welcomeSearch{
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .welcomeSearch input{
    width: 120px;
    height:   15px;
    font-size: 10px;
    text-align: center;
  }
  .searchBtn {
    width: 60px;
    height: 25px;
    font-size: 10px;
    background-color: rgb(208, 15, 215);
  }
  .cardheadBkgMobile {
    display: none;
  }
}
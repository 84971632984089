.navSite{
  display: flex;
  width: 100vw;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: fixed;
  margin-top: 0;
  padding: 10px 30px 10px 30px;
  background-color: #ffffff00 !important;
  z-index: 1;
}
.navSite logo {
  width: 90px;
  height: 90px;
}
.navSite .logo img{
  width: 120px;
  height: 120px;
  position: absolute;
}
.navSite .title {
  position: fixed;
}
.navSite .log {
  margin-right: 130px;
  cursor: pointer;
  color: #197dc5;
  font-size: 18px;
  font-weight: 500;
}
.navSite ul {
  display: flex;
  width: 500px;
  height: 30px;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.navSite li a {
  width: 170px  !important;
  height: 30px  !important;
  background-color: transparent !important;
  font-size: 1.2rem;
  color: #ffffff;
  text-shadow: 3px 3px 5px #04324b;
}

.navSite li :hover {
  color: #ffffff;
  text-shadow: 3px 3px 5px #091319;
}

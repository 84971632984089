.actions {
  width: 735px;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  background-color: rgba(244, 248, 248, 0.59);
}
.showInfoTitle{
  font-size: 20px;
  margin-bottom: 10px;
}
.showInfoTitle label{
  color: #258cd6e2;
  font-weight: bold;
}
.showInfo{
  display: flex;
  width: 100vw;
  height: 500px auto;
  font-size: 13px;
}
.showLeft{
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 500px auto;
}
.showRight{
  display: flex;
  width: 50%;
  height: 500px auto;
}
.banner_url{
  display: flex;
  width: 300px;
  height: 200px;
  justify-content: center;
  align-items: center;
  border: none !important;
}
.bannerCei{
  max-width: 300px;
  max-height: 200px;
  object-fit: cover; 
}
.showInfoLines {
    display: flex;
    justify-content:space-around;
    align-items: center;
    width: 700px;
    height: 35px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: rgba(112, 181, 234, 0.353);
}
.showInfoLines label{
  color: #258cd6e2;
  font-weight: bold;
}
.showInfoLines span {
  margin-left: 0px;
}

.lineBoxA{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 25px;
  width: 125px;
  background-color:  rgb(241, 244, 244);
  padding-right: 10px;
  border-radius: 5px;
}
.lineBoxB{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 25px;
  width: 535px;
  padding-left: 10px;
  background-color: rgb(241, 244, 244);
  border-radius: 5px;
}
.printBtn{
  display: flex;
  justify-content: space-around;
  align-content: center;
  width: 90px;
  height: 30px;
  padding-top: 5px;
}
.createStores, form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
  gap: 10px;
}

.form-group {
  border-top: 2px solid #258cd615;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.group {
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 5px;
  font-size: 12px;
}

.group input {      
  width: 250px;
  height: 16px;
  font-size: 12px;
}
.group input[type="file"] {
  display: none;
}
.banner_url {
  width: 250px;
  height: 16px;
  font-size: 12px;
  border: 1px solid #ccc;
  display: inline-block;
  cursor: pointer;
}
.banner_url:hover {
  background-color: #f0f0f0;
}
.group .colA {
  width: 100%;
  text-align: left;  
}

.group .colB {
  color: #258cd614;
}

.selectList {
  width: 250px;
  height: 20px;
  border-radius: 50px;
  font-size: 12px;
  background-color: #258cd614;
}

.selectList.show{
  background-color: #258cd614;
  color: red;
}

.btnForm{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 50px;
}

.company_id { 
display: none;
}

.stores {
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
}

.message {
  color: rgb(213, 79, 13);
}

.updateBtn {
  width: 60px;
  color: rgb(5, 23, 71);
  border-radius: 50px;
  background-color: #94bcd963;
}

.deleteBtn {
  width: 60px;
  color: rgb(213, 79, 13);
  border-radius: 50px;
  background-color: #e6141414;
  margin-left: 10px;
}
.pdfBtn {
  width: 60px;
  color: rgb(20, 107, 126);
  border-radius: 50px;
  background-color: #13a3c714;
  margin-left: 10px;
}
.emailBtn {
  width: 60px;
  color: rgb(18, 170, 92);
  border-radius: 50px;
  background-color: #0ca06735;
  margin-left: 10px;
}
.chooseFileBtn{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  margin-left: 0px;
  background-color:  #258cd615;
  border: 1px solid  #0c81da99;
}

.stores table {
  font-size: 12px;
}

.stores table .tdBtn {
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-wrap: wrap;
}

.pagination button {
  width: 20px;
  height: 20px;
  margin-top: 15px;
  border: none;
  border-radius: 2px !important;
  margin-left: 5px;
  text-align: center;
  padding: 0px;
  background-color: #94bcd963;
  color: white;
}

.pagination button.active {
  color: rgb(5, 23, 71);
  background-color: #799fbc;
}

#storesMobile {
 display: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Adicione um fundo escuro semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ajuste conforme necessário */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%; /* Ajuste conforme necessário */
  max-width: 600px; /* Ajuste conforme necessário */
  max-height: 80vh; /* Ajuste conforme necessário */
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.listLinkBtn{
  border: none !important;
  background-color: transparent !important;
}
.listLinkBtn:hover { 
  background-color: #258cd614;
  cursor: pointer;
}
tr:hover{ 
  background-color: #258cd614;
  border: none  !important;
}
.selectedLogo{
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
/* MOBILE */
@media only screen and (max-width: 980px) {
  .stores{
    display: none;
  }
     
  #storesMobile {
      display: block;
   }
}
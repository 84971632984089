.users table {
  width: 1200px;
  font-size: 14px;
}
thead th {
  text-align: left;
}
.updateBtn {
  width: 60px;
  color: rgb(5, 23, 71);
  border-radius: 50px;
  background-color: #94bcd963;
}
.deleteBtn {
  width: 60px;
  color: rgb(213, 79, 13);
  border-radius: 50px;
  background-color: #e6141414;
  margin-left: 10px;
}

@import url('https://fonts.googleapis.com/css2?family=Gluten&family=K2D:wght@200&display=swap');

.mainLogin{
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url('../../public/img/bckgLogin.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.sendForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  height: 350px;
  border: 1px solid #258CD6;
  border-radius: 10px;
  padding: 2px;
  background-color: #eef2f682;
  line-height: 2;
}
.formgroup{
  display: flex;
  flex-direction: column;
  width: 250px;
}

.links{
  display: flex;
  flex-direction: column;
}
.username{
  width: 220px;
  height: 20px;
}
.password{ 
  width: 220px;
  height: 20px;
}
.sendBtn{
  display: flex;
  width: 240px;
  margin-top: 40px;
}
button.send{
  margin: 0;
  width: 220px;
  height: 25px;
  background-color: #258cd64b;
}
button {
  background-color: #258cd64b;
  height: 30px;
  border-radius: 50px;
  border: 1px solid #258CD6;
}
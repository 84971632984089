.App {
  background-image: url('../../../public/img/aicaalogoG.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

.title{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  margin-left: 20px;
}

.linkLogin {
  color: whitesmoke;
}
.imgBanner {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;

}
.carouselSlide {
  width: 80vw;
  height: 100vh;
}
.slick-dots {

}
.banText{
  margin: auto;
  margin-left: 300px;
  margin-top: 150px;
  width: 1280px;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  color: #fff;
}
.banText h1{
  font-size: 4rem;
}
.banText p{
  font-size: 2rem;
}
.section2{
  width: 100vw;
  height: 100vh;
  /* padding-top: 100px; */
  /* background-color: rgb(42, 42, 42); */
}
.sec2Bkg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}
.sec2Bkg img {
  width: 900px;
  height: 340px;
}
.fotPlay {
  display: flex;
  justify-content: flex-end;
}
.fotPlay img {
  width: 300px;
  height: 350px;
  margin-right: 30px;
}
.cardUploadImg{
  margin: 0px;
  padding: 2px;
  width: 250px;
  height: 250px;
  border: 1px solid #e5e5e5;
  background: linear-gradient(to top, white, rgba(12, 162, 242, 0.2));
  border-radius: 15px;
}
.cardheaderUploadImg{
  display: flex;
  justify-content: center;
  width: 250px;
  height: 100px;
}
.avatarcardUploadImg{
  width: 100px;
  height: 100px;
  margin-top: 15px;
  border-radius: 50px;
  font-size: 10px;
}
.cardbodyUploadImg{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  height: 100px;
}
.cardtitlename{
  display: flex;
  width: 250px;
  justify-content: center;
  font-size: 16px;
}
.carddataadd{
  display: flex;
  width: 250px;
  justify-content: center;
  font-size: 14px;
}
.cardbuttonUploadImg{
  width: 250px;
  display: flex;
  justify-content: center;
  width: 250px;
  height: 50px;
}
.btncardUploadImg{
  margin: 0px;  
  width: 100px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #39bbe7;
  background: #f44434dd;
  color: #f5f5f5;
  font-size: 15px;
  font-weight: bold;
}
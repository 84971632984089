.section3 {
  width: 100vw;
  height: 100vh;
  /* padding-top: 100px; */
  /* background-color: rgb(42, 42, 42); */
  color: rgb(167, 219, 219);
}
.main {
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  width: 980px;
  height: 600px;
  padding-top: 150px;
}
form{
  margin: 0;
  padding: 0;
}
.contact-Us{
  display: flex;
  justify-content: space-evenly;
  align-items: center; 
  width: 300px;
  margin: auto;
  flex-direction: column;
}
.contact-Us{
  width: 980px;
  height: 400px;
  border: 1px solid #fff;
  border-radius: 15px;
}
.group-Us{
  display: flex;
  width: 980px;
  justify-content: center;
  gap: 25px;
}
.message-Us{
  width: 250px;
}
.email-Us{
  width: 250px;
}
.btn-Us{
  display: flex;
  margin-top: 50px;
  justify-content: center;
}
.btn-Us>button{
  color: #fff;
  height: 30px;
}
.bank-Us{
   width: 980px;
  display: flex;
  justify-content: space-around;
  gap: 60px;
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 5px;
}

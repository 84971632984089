@import url('https://fonts.googleapis.com/css2?family=Gluten&family=K2D:wght@200&display=swap');

.content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  gap: 10px;
}
.card{
  width: 350px;
  height: 650px auto;
  padding: 20px;
  border-radius: 10px;
  font-family: "K2D", "Helvetica Neue", Helvetica, Arial;
  background: linear-gradient(to top, #fff, #258cd632);
}
.cardhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  margin-top: -10px;
  z-index: 1;
  position: relative;
}
.cardheadBkg {
  width: 390px;
  height: 100px;
  margin-left: -20px;
  margin-top: -20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: absolute;
  background: linear-gradient(to top, #e2dcdc75, #258cd680);
  z-index: 0;
}

.cardhead img {
  width: 65px;
  height: 65px;
}
.cardCnpj {
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  margin-top: -10px;
  margin-bottom: 25px;
}
.contact {
  width: 350px;
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px;
}
.cardBlock {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
}
.cardRow {
  display: flex;
  gap: 10px;
}
.card span {
  font-size: 1rem;
  color: #258cd6;
  font-weight: bold;
}
.contact img {
  width: 30px;
  height: 30px;
}
.contactLink {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 0px;
}
.contactLink img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.services {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
a {
  color: black;
}
a:hover {
    font-weight: bold;
    }
    .email a {
      margin-left: 25px;
      }

/* MOBILE */
@media only screen and (max-width: 600px) {
  body {
    font-size: 12px;
    overflow-x: hidden;
  }
  .card{
    width: 300px;
    height: 650px auto;
    padding: 5px;
    border-radius: 10px;
    font-family: "K2D", "Helvetica Neue", Helvetica, Arial;
    background: linear-gradient(to top, #fff, #258cd632);
    font-size: 14px;
  }
  .cardheadBkg {
   display: none;
  }
  .cardhead img {
    width: 45px;
    height: 45px;
  }
  .card span {
    font-size: 12px;
    color: #258cd6;
    font-weight: bold;
  }
  .contact {
    width: 250px;
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: bold;
    margin-top: 10px;
  }
  label {
    font-size: 12px;
  }
}
body {
  font-family: Arial, sans-serif;
  background-color: none !important;
}

.container{
  margin: auto;
  max-width: 1500px;
  padding: 0 50px;
}

.navUploadImg {
  display: flex;
  justify-content: space-between;
  height: 80px;
  margin-bottom: 80px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #23687f6d;
  background: linear-gradient(to top, #fff, #2c92dca3);
  padding: 10px; /* Adapte o preenchimento conforme necessário */
  z-index: 1000; /* Garante que o nav fique acima de outros elementos na página */
}
.navbarUploadImg{
  display: flex;
  width: 1400px;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  /* background-color: rgb(30, 241, 125); */
}
.navbrandUploadImg{
  display: flex;
  width: 200px;
  height: 50px;
  justify-content: flex-start;
  align-items: center;
  /* background-color: yellow; */
}
.navbrandUploadImg img {
  width: 100px;
  height: 100px;
  margin-top: 60px;
}
.navlinkUploadImg {
  display: flex;
  width: 500px;
  padding: 10px;
  justify-content: space-around;
  margin-right: 650px;
}
.navlinkUploadImg a{
  color: rgb(25, 79, 126);
}
/* HomeCards */
.home-cards{
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.add-user{
  display: flex;
  justify-content: flex-end;
  height: 50px;
  margin-top: 110px;
}
.counterRegister{
  display: flex;
  justify-content: flex-end;
  height: 50px;
}
.btn-add-user{
  width: 100px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #39bbe7;
  /* background: #e74839; */
  color: #f5f5f5;
  font-size: 15px;
  font-weight: bold;
}
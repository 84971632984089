section.upload-img {
  width: 1400px;
  height: 500px;
  margin-top: 160px;
}
.formUploadImg {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn-upload-img {
  width: 100px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #39bbe7;
  /* background: #e74839; */
  color: #f5f5f5;
  font-size: 15px;
  font-weight: bold;
}